import React from "react";

export const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 56.693 56.693"
    xmlSpace="preserve"
    aria-label="Facebook Icon"
  >
    <path d="M40.43 21.739h-7.645v-5.014c0-1.883 1.248-2.322 2.127-2.322h5.395V6.125l-7.43-.029c-8.248 0-10.125 6.174-10.125 10.125v5.518h-4.77v8.53h4.77v24.137h10.033V30.269h6.77l.875-8.53z"></path>
  </svg>
);

export const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    x="0"
    y="0"
    enableBackground="new 0 0 56.693 56.693"
    version="1.1"
    viewBox="0 0 56.693 56.693"
    xmlSpace="preserve"
    aria-label="Twitter Icon"
  >
    <path d="M54.082 15.55a20.14 20.14 0 01-5.805 1.59 10.13 10.13 0 004.444-5.591 20.231 20.231 0 01-6.418 2.454 10.093 10.093 0 00-7.377-3.192c-5.581 0-10.107 4.525-10.107 10.107 0 .792.09 1.563.262 2.302-8.4-.42-15.848-4.444-20.832-10.56a10.058 10.058 0 00-1.369 5.082c0 3.506 1.785 6.6 4.497 8.412a10.07 10.07 0 01-4.578-1.264l-.001.128c0 4.896 3.484 8.98 8.108 9.91a10.143 10.143 0 01-4.565.173c1.287 4.015 5.019 6.937 9.44 7.019A20.277 20.277 0 017.23 46.446c-.816 0-1.62-.048-2.411-.14a28.606 28.606 0 0015.493 4.54c18.59 0 28.756-15.4 28.756-28.757 0-.437-.009-.874-.028-1.307a20.518 20.518 0 005.042-5.232z"></path>
  </svg>
);

export const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    x="0"
    y="0"
    enableBackground="new 0 0 56.7 56.7"
    version="1.1"
    viewBox="0 0 56.7 56.7"
    xmlSpace="preserve"
    aria-label="Instagram Icon"
  >
    <path d="M28.2 16.7c-7 0-12.8 5.7-12.8 12.8s5.7 12.8 12.8 12.8S41 36.5 41 29.5s-5.8-12.8-12.8-12.8zm0 21c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2 8.2 3.7 8.2 8.2-3.7 8.2-8.2 8.2z"></path>
    <circle cx="41.5" cy="16.4" r="2.9"></circle>
    <path d="M49 8.9c-2.6-2.7-6.3-4.1-10.5-4.1H17.9c-8.7 0-14.5 5.8-14.5 14.5v20.5c0 4.3 1.4 8 4.2 10.7 2.7 2.6 6.3 3.9 10.4 3.9h20.4c4.3 0 7.9-1.4 10.5-3.9 2.7-2.6 4.1-6.3 4.1-10.6V19.3c0-4.2-1.4-7.8-4-10.4zm-.6 31c0 3.1-.9 5.6-2.7 7.3-1.8 1.7-4.3 2.6-7.3 2.6H18c-3 0-5.5-.9-7.3-2.6C8.9 45.4 8 42.9 8 39.8V19.3c0-3 .9-5.5 2.7-7.3 1.7-1.7 4.3-2.6 7.3-2.6h20.6c3 0 5.5.9 7.3 2.7 1.7 1.8 2.7 4.3 2.7 7.2v20.6z"></path>
  </svg>
);

export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="icon"
    viewBox="0 0 24 24"
    aria-label="Search Icon"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <path d="M21 21L16.65 16.65"></path>
  </svg>
);

export const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="icon"
    aria-label="Dropdown Menu Icon"
    viewBox="0 0 24 24"
  >
    <path d="M3 12L21 12"></path>
    <path d="M3 6L21 6"></path>
    <path d="M3 18L21 18"></path>
  </svg>
);

export const AlertOctagon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="icon"
    viewBox="0 0 24 24"
    aria-label="Octagon Alert Icon"
  >
    <path d="M7.86 2L16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2z"></path>
    <path d="M12 8L12 12"></path>
    <path d="M12 16L12.01 16"></path>
  </svg>
);

export const XIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="icon"
    viewBox="0 0 24 24"
    aria-label="X Icon"
  >
    <path d="M18 6L6 18"></path>
    <path d="M6 6L18 18"></path>
  </svg>
);

export const UpArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="feather feather-chevron-up"
    viewBox="0 0 24 24"
    aria-label="Up Arrow Icon"
  >
    <path d="M18 15L12 9 6 15"></path>
  </svg>
);
