import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import { UpArrowIcon } from "@icon";

import * as styles from "@styles/components/Footer/index.module.scss";

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      content(component_id: { eq: "footer" }) {
        copyright
        navigation {
          name
          url
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { copyright, navigation } = data.content as {
    copyright: string;
    navigation: { name: string; url: string }[];
  };

  const copyrightText =
    copyright && copyright !== ""
      ? copyright
      : `${data.site.siteMetadata.title} © ${new Date().getFullYear()}`;

  const goTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <footer className={styles.footer}>
      <nav>
        <ul>
          {navigation &&
            navigation.map((item, index) => {
              const { name, url } = item;

              return (
                <li key={index}>
                  <Link to={url} target="_self">
                    {name}
                  </Link>
                </li>
              );
            })}
        </ul>
      </nav>

      <p>{copyrightText}</p>

      <div className={styles.top} onClick={goTop}>
        <UpArrowIcon /> Top
      </div>
    </footer>
  );
};

export default Footer;
