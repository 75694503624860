import React from "react";

import * as styles from "@styles/components/UI/drawer.module.scss";

type Props = {
  visible: boolean;
  className?: string;
};

const Drawer: React.FC<Props> = ({ visible, className, children }) => {
  return (
    <div
      className={`${styles.drawer} ${
        visible ? styles.drawer__visible : ""
      } ${className}`}
    >
      {children}
    </div>
  );
};

export default Drawer;
