import React, { useEffect, useRef, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import { getImage, GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useLunr } from "react-lunr";

import { MenuIcon, SearchIcon, XIcon } from "@icon";
import Drawer from "@components/UI/Drawer";
import Navigation from "./Navigation";

import * as styles from "@styles/components/Header/index.module.scss";

const Header: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Header {
      localSearchPages {
        index
        store
      }
      content(component_id: { eq: "header" }) {
        logo {
          childImageSharp {
            gatsbyImageData(height: 100)
          }
        }
        header_navigation
        socials {
          facebook
          twitter
          instagram
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const searchInput = useRef<HTMLInputElement>(null);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [searchDrawer, setSearchDrawer] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { logo, header_navigation } = data.content as {
    logo: any;
    header_navigation: string[];
    socials: {
      facebook: string;
      twitter: string;
      instagram: string;
    };
    localSearchPages: any;
  };

  const searchResults = useLunr(
    searchQuery,
    data.localSearchPages.index,
    data.localSearchPages.store
  ) as {
    date: string;
    excerpt: string;
    slug: string;
    title: string;
  }[];

  useEffect(() => {
    const handleVisibility = (event: any) => {
      if (
        searchInput.current &&
        !searchInput.current.contains(event.target) &&
        searchInput.current.offsetHeight !== 0
      ) {
        setSearchDrawer(false);
      }
    };

    window.addEventListener("click", handleVisibility);
    return () => {
      window.removeEventListener("click", handleVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (drawer) {
      setSearchDrawer(false);
    }
  }, [drawer]);

  useEffect(() => {
    if (searchDrawer) {
      setDrawer(false);
    }
  }, [searchDrawer]);

  return (
    <>
      <header
        className={`${styles.header} ${
          searchResults.length > 0 && searchDrawer ? styles.header__sticky : ""
        }`}
      >
        <form
          onSubmit={(e) => e.preventDefault()}
          className={`${styles.search__drawer} ${
            searchDrawer ? styles.search__drawer__open : ""
          }`}
        >
          <input
            type="text"
            ref={searchInput}
            placeholder="Search site..."
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <input type="button" value="Search" hidden />
        </form>

        <div className={styles.header__main}>
          <div
            onClick={() => {
              setSearchDrawer((prevState) => !prevState);

              if (searchInput.current) {
                searchInput.current.focus();
              }
            }}
            className={styles.search}
          >
            <SearchIcon />
          </div>

          <Link to="/" className={styles.logo} target="_self">
            <GatsbyImage
              image={getImage(logo) as IGatsbyImageData}
              alt={`${data.site.siteMetadata.title} logo`}
            />
          </Link>

          <button
            onClick={() => {
              setDrawer((prevState) => !prevState);
            }}
            className={styles.button}
          >
            {drawer ? <XIcon /> : <MenuIcon />}
          </button>
        </div>

        <Drawer visible={drawer} className={styles.drawer}>
          <ul className={styles.content__list}>
            {header_navigation.map((item, index) => {
              return (
                <li className={styles.list__item} key={index}>
                  <Link to={`/category/${item}`} target="_self">
                    {item}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Drawer>

        <Drawer
          visible={searchResults.length > 0 && searchDrawer}
          className={styles.search__options}
        >
          <ul>
            {searchResults.map((item, index) => {
              const { title, slug, date, excerpt } = item;

              return (
                <li key={index}>
                  <Link to={`/blog/${slug}`}>
                    <h3>{title}</h3>
                  </Link>
                  <span>{date}</span>
                  <p>{excerpt}</p>
                </li>
              );
            })}
          </ul>
        </Drawer>

        <Navigation navigation={header_navigation} />
      </header>
    </>
  );
};

export default Header;
