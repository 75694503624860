import React from "react";
import { Link } from "gatsby";

import * as styles from "@styles/components/Header/navigation.module.scss";

type Props = {
  navigation: string[];
};

const Navigation: React.FC<Props> = ({ navigation }) => {
  return (
    <nav className={styles.nav}>
      <ul>
        {navigation.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`/category/${item}`} target="_self">
                {item}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
